import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import MDXPage from '../components/MDXPage';
import PageMeta from '../components/PageMeta';
import Section from '../components/Section';
import FloatingNavbar from '../components/FloatingNavbar';
import MapboxMap from '../components/MapboxMap';
import FilterPhrase from '../components/FilterPhrase';
import StateDropdown from '../components/StateDropdown';
import Table from '../components/Table';
import titleHTML from '../../content/overview/title.html';
import introductionHTML from '../../content/overview/introduction.html';
import universitiesHTML from '../../content/overview/universities.html';
import tribesHTML from '../../content/overview/tribes.html';
import landsHTML from '../../content/overview/lands.html';
import styles from './Overview.module.css';
export const remainMountedChildren = <>
    <section className={styles.titleBlock} dangerouslySetInnerHTML={{
    __html: titleHTML
  }} />
    <FloatingNavbar anchorLinks={[{
    href: '#introduction',
    label: 'Introduction'
  }, {
    href: '#map',
    label: 'Map'
  }, {
    href: '#universities',
    label: 'Universities Intro'
  }, {
    href: '#tribes',
    label: 'Tribal Nations Intro'
  }, {
    href: '#lands',
    label: 'Lands Intro'
  }]} mdxType="FloatingNavbar" />
    <a name="introduction" />
    <section className={styles.introductionBlock} dangerouslySetInnerHTML={{
    __html: introductionHTML
  }} />
    <a name="map" />
    <MapboxMap key="map" mapTitle={<h4>
          {'Indigenous Land Granted to Universities in '}
          <StateDropdown mdxType="StateDropdown" />
        </h4>} mapSubtitle="Morrill Act parcels and recipient schools" mdxType="MapboxMap" />
  </>;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  remainMountedChildren,
  _frontmatter
};
const MDXLayout = MDXPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <PageMeta title="Overview" mdxType="PageMeta" />
    <a name="universities" />
    <section className={styles.universitiesBlock} dangerouslySetInnerHTML={{
      __html: universitiesHTML
    }} />
    <Section paragraphWidth mdxType="Section">
  <FilterPhrase name="universities-in-state-raised" mdxType="FilterPhrase" />
    </Section>
    <Section figureWidth mdxType="Section">
  <Table name="universities-for-selected-grant-states" isOverview pageSize={10} mdxType="Table" />
    </Section>
    <a name="tribes-all" />
    <section className={styles.tribesBlock} dangerouslySetInnerHTML={{
      __html: tribesHTML
    }} />
    <Section figureWidth mdxType="Section">
  <Table name="tribes-all" isOverview pageSize={10} mdxType="Table" />
    </Section>
    <Section figureWidth mdxType="Section">
  <p>
    <em>
      <b>NOTE</b>: Our data shows land cessions that are associated with
      multiple tribal nations. These totals add up to more than 100% of total
      acreage, amount paid, and amount raised. The names above appear as they
      did at time of cession. Many are not in use today, and some are considered
      offensive. For more information, <a href="#about">see our methodology</a>.
    </em>
  </p>
  <p>&nbsp;</p>
    </Section>
    <a name="lands" />
    <section className={styles.landsBlock} dangerouslySetInnerHTML={{
      __html: landsHTML
    }} />
    <Section paragraphWidth mdxType="Section">
  <FilterPhrase name="parcels-given-to-universities-in-state" mdxType="FilterPhrase" />
    </Section>
    <Section figureWidth mdxType="Section">
  <Table name="lands-for-selected-grant-states" isOverview pageSize={10} mdxType="Table" />
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      